<template>
    <div class="menu-container w-100">
        <v-row v-if="props.displayMode == MenuDisplayMode.tile && itemsToDisplay" dense justify="space-around">
            <v-col v-for="item in itemsToDisplay" :key="item.name" md="2" cols="6">
                <router-link :to="item.route" class="menu-item">
                    <v-card light @click="onItemClicked(item)">
                        <v-img :src="item.backgroudImage"
                               class="white--text align-end"
                               gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                               height="200px">
                            <v-card-title>{{getItemTitle(item)}}</v-card-title>
                        </v-img>
                    </v-card>
                </router-link>
            </v-col>
        </v-row>
        <div v-else-if="props.displayMode == MenuDisplayMode.button && itemsToDisplay" class="admin-menu">
            <v-row justify="center">
                <v-btn v-for="item in itemsToDisplay" :key="item.name" small
                       color="primary"
                       dark
                       class="ma-2 text-none"
                       link
                       :to="item.route"
                       @click="setCurrentAdminMenu(item)">
                    {{getItemTitle(item)}}
                </v-btn>
            </v-row>
            <v-row v-if="currentAdminMenu">

                <v-btn v-for="item in currentAdminMenu.subMenuItems" :key="item.name" small
                       color="secondary" class="ma-2 text-none" link :to="item.route" outlined>
                    {{getItemTitle(item)}}
                </v-btn>
            </v-row>
        </div>
        <v-list v-else-if="props.displayMode == MenuDisplayMode.list && itemsToDisplay" nav dense
                class="menu-list overflow-y-auto overflow-x-hidden no-scrollbar"
                :opened="listGroupOpen">
            <v-list-group v-for="item in itemsToDisplay" :key="item.name" :value="item.name"
                          :prepend-icon="item.icon || mdiFolder" color="secondary"
                          :class="{'list-item-no-child': !item.subMenuItems || item.subMenuItems.length == 0, 'mt-auto': item.isAdminMenu}">
                <template v-slot:activator="{ props }">
                    <v-list-item :title="getItemTitle(item)" v-bind="props"
                                 @click="onItemClicked(item)">
                        <template v-slot:append="{ isActive }">
                            <v-icon small
                                    :icon="item.subMenuItems?.length > 0 ? (listGroupOpen.includes(item.name) ? mdiChevronDown : mdiChevronRight): ''"
                                    @click="expandGroup($event, item.name)"></v-icon>
                        </template>
                    </v-list-item>
                </template>

                <v-list-item v-for="subItem in item.subMenuItems" :key="getItemTitle(subItem)" class="ml-3 subItem" link
                             :to="subItem.route"
                             :title="getItemTitle(subItem)">
                    <template v-slot:prepend>
                        <v-icon size="small" :icon="subItem.icon || mdiFileImage"></v-icon>
                    </template>
                </v-list-item>
            </v-list-group>
        </v-list>

        <div v-else-if="props.displayMode == MenuDisplayMode.breadcrumb"
             :class="['breadcrumb', 'breadcrumbs-placeholder', 'd-flex', {'justify-end' : isMainPageAndMixedBreadcrumb }]">
            <div ref="breadcrumbsContainer"
                 :class="['breadcrumbs-container', 'no-scrollbar', { 'breadcrumbs-container-open': menuPopupIsOpen }]">
                <v-breadcrumbs :items="breadcrumbsItems"
                               class="items-breadcrumbs flex-nowrap pa-0 no-scrollbar">
                    <template v-slot:title="{ item }">
                        <v-hover v-slot="{ isHovering, props }" v-model="breadcrumbsPopupStates[item.name]">
                            <div v-bind="props" ref="popupMenu" class="popup-menu">
                                <div :class="getBreadcrumbItemClasses(item, isHovering)">
                                    <v-icon v-if="breadcrumbsItems.indexOf(item) == 0 && !isMainPageAndMixedBreadcrumb"
                                            :icon="mdiHome" class="home-icon"
                                            v-on="{ touchend: e => onTouchEnd(e, item)}"></v-icon>
                                    <span v-else class="text-no-wrap"
                                          v-on="{ touchend: e => onTouchEnd(e, item)}">{{getItemTitle(item)}}</span>
                                </div>
                                <v-list ref="breadcrumbsItemMenu" v-if="isHovering"
                                        v-bind="props" density="compact"
                                        :class="['breadcrumbs-item-menu', { 'align-left': isHomeBreadcrumbItem(item) }]"
                                        color="primary">
                                    <v-list-item v-for="(subItem, i) in getBreadcrumbSubItems(item)"
                                                 :key="`${item.name}-${subItem.name}`"
                                                 :to="subItem.to" class="menu-item" :title="getItemTitle(subItem)"
                                                 :value="subItem.name">
                                        <template v-slot:prepend>
                                            <v-icon :icon="subItem.icon" size="large"></v-icon>
                                        </template>
                                    </v-list-item>
                                </v-list>
                            </div>
                        </v-hover>
                    </template>
                    <template v-slot:divider>
                        <v-breadcrumbs-item>
                            <v-icon v-if="!isMainPageAndMixedBreadcrumb" :icon="mdiForward"></v-icon>
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        mdiCog, mdiHome, mdiForward, mdiFolder,
        mdiChevronDown, mdiChevronRight, mdiFileImage, mdiChevronLeft
    } from "@mdi/js";
    import { type BreadcrumbsItem, type EMenuItem } from "~/parts/menu/types";
    import { getMenuItems, getBreadcrumbsItems, refreshMenuItems } from "~/parts/menu";
    import { type RouteLocationNamedRaw } from "vue-router";

    //#region Data
    const route = useRoute();
    const listGroupOpen = ref<string[]>([]);
    const { menuItems } = getMenuItems();
    const currentAdminMenu = ref<EMenuItem>({} as EMenuItem);
    const breadcrumbsPopupStates = ref<{ [key: string]: boolean }>({});
    const popupMenu = ref<HTMLElement | null>(null);
    const breadcrumbsContainer = ref<HTMLElement | null>(null);
    const breadcrumbsItemMenu = ref<any>(null);
    const { height: popupMenuHeight } = useElementBounding(popupMenu);
    const { onSignOut } = useEAuth();
    //#endregion

    //#region Props
    const props = defineProps({
        name: {
            type: String,
            required: true
        },
        displayMenu: {
            type: String,
            default: null
        },
        isAdminMenu: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array<EMenuItem>,
            default: () => null
        },
        displayMode: {
            type: String,
            required: true
        },
        isBreadcrumbAndMainMenu: {
            type: Boolean,
            required: false,
            default: false
        }
    });
    //#endregion

    //#region Computed
    const mainMenuItems = computed(() => {
        const menusItems = props.items ?? menuItems.value;
        return removeDynamicItem(Array.from(menusItems));
    });

    const subMenuItems = computed(() => {
        let menusItems: Array<EMenuItem> | undefined = menuItems?.value.find(i => i.name == props.displayMenu)?.subMenuItems;
        menusItems = !!menusItems ? removeDynamicItem([...menusItems]) : undefined;
        return menusItems?.sortBy(mi => mi.displayOrder) || [];
    });

    const adminMenuItems = computed(() => {
        let adminMenuItems: Array<EMenuItem> | undefined = menuItems.value?.find(i => i.isAdminMenu)?.subMenuItems;
        if (props.displayMenu)
            adminMenuItems = adminMenuItems?.find(i => i.name == props.displayMenu)?.subMenuItems;
        adminMenuItems = removeDynamicItem([...(adminMenuItems ?? [])]);
        return adminMenuItems?.sortBy(mi => mi.displayOrder) || [];
    });

    const itemsToDisplay = computed(() => {
        if (props.isAdminMenu)
            return adminMenuItems.value;
        else if (props.displayMenu)
            return subMenuItems.value;
        else
            return mainMenuItems.value;
    });

    const breadcrumbsItems = computed(() => {
        const breadcrumbsItems = getBreadcrumbsItems().value;
        return isMainPageAndMixedBreadcrumb.value ? breadcrumbsItems[0].subMenuItems : breadcrumbsItems;
    });

    const isMainPageAndMixedBreadcrumb = computed(() => {
        return route.name == "index" && props.isBreadcrumbAndMainMenu;
    });

    const menuPopupIsOpen = computed(() => {
        return Object.entries(breadcrumbsPopupStates.value).some(([_, value]) => value);
    });
    //#endregion

    //#region Methods
    const { t } = useI18n();

    function setCurrentAdminMenu(itemMenu: EMenuItem) {
        currentAdminMenu.value = itemMenu;
        if (currentAdminMenu.value) {
            expandGroup(null, currentAdminMenu.value.parentName ?? currentAdminMenu.value.name);
        }
    }

    function findAndSetActiveMenuItem(itemName: string) {
        let menuItem = adminMenuItems.value.find(menu => itemName.startsWith((menu.route as RouteLocationNamedRaw).name as string)) as EMenuItem;
        setCurrentAdminMenu(menuItem);
        if (!menuItem) {
            menuItem = menuItems.value?.find(mi => itemName.startsWith((mi.route as RouteLocationNamedRaw).name as string)) as EMenuItem;
            if (menuItem)
                expandGroup(null, menuItem.parentName ?? menuItem.name);
        }
    }

    function onItemClicked(item: EMenuItem | null = null) {
        if (!!item) {
            if (!listGroupOpen.value.includes(item.name)) {
                listGroupOpen.value = [...listGroupOpen.value, item.name];
            }
            navigateTo(item.route);
        }
    }

    function expandGroup(e: Event | null, groupName: string) {
        e?.stopPropagation();
        if (listGroupOpen.value.includes(groupName)) {
            listGroupOpen.value = listGroupOpen.value.filter(s => s != groupName);
        } else {
            listGroupOpen.value = [...listGroupOpen.value, groupName];
        }
    }

    function removeDynamicItem(list: Array<EMenuItem>): Array<EMenuItem> {
        if (list) {
            return list.reduce((acc, curr) => {
                if (!curr.isDynamic) {
                    const item = { ...curr };
                    if (item.subMenuItems)
                        item.subMenuItems = removeDynamicItem(item.subMenuItems);
                    acc.push(item);
                }
                return acc;
            }, [] as Array<EMenuItem>);
        }
        return list;
    }

    function onTouchEnd(event: Event, item: EMenuItem) {
        event.stopPropagation();
        const breadcrumbsItems = getBreadcrumbsItems();
        if (!!item.subMenuItems?.filter((i: EMenuItem) => !i.isDynamic).length) {
            breadcrumbsItems.value.forEach(i => {
                if (item.name != i.name)
                    toggleTooltip(i.name, false);
            });
            const menuIsOpen = breadcrumbsPopupStates.value[item.name];
            if (!menuIsOpen)
                event.preventDefault();
            toggleTooltip(item.name, !menuIsOpen);
        }
    }

    function toggleTooltip(key: string, value: boolean) {
        breadcrumbsPopupStates.value[key] = value;
    };

    function getBreadcrumbSubItems(item: BreadcrumbsItem): Array<BreadcrumbsItem> {
        return item.subMenuItems?.filter(mi => !mi.isDynamic) ?? [];
    }

    function popupClickOutside(event: Event) {
        Object.entries(breadcrumbsPopupStates.value).forEach(([k, _]) => {
            breadcrumbsPopupStates.value[k] = false;
        });
    }

    function getBreadcrumbItemClasses(item: BreadcrumbsItem, isHovering: boolean) {
        const displayableSubMenuItems = getBreadcrumbSubItems(item);
        return [
            'breadcrumbs-item-node',
            'pointer',
            {
                'breadcrumbs-item-node-active': isHovering &&
                    (displayableSubMenuItems && !!displayableSubMenuItems.length || isMainPageAndMixedBreadcrumb.value)
            }
        ];
    }

    function isHomeBreadcrumbItem(item: BreadcrumbsItem) {
        return breadcrumbsItems.value?.indexOf(item) == 0;
    }

    function getItemTitle(item: EMenuItem) {
        if (item.localeTitleKey)
            return t(item.localeTitleKey);
        return item.title;
    }
    //#endregion

    //#region Hooks
    onBeforeMount(() => {
        findAndSetActiveMenuItem(route.name as string);
    });

    onMounted(() => {
        onClickOutside(popupMenu, popupClickOutside);
    });

    onSignOut(async () => {
        await refreshMenuItems(useNuxtApp());
    });
    //#endregion

    //#region Watchers
    watch(menuPopupIsOpen, (newValue, _) => {
        if (newValue) {
            nextTick(() => {
                const offset = 20;
                const windowOffset = { x: offset, y: 0 };
                const { left, right, hiddenRight, hiddenLeft } = useElementPartiallyVisibility(breadcrumbsItemMenu.value, windowOffset).value;
                const { x } = useScroll(breadcrumbsContainer.value);
                if (!left) {
                    x.value -= hiddenLeft + offset;
                } else if (!right) {
                    x.value += hiddenRight + offset;
                }
            });
        }
    });
    //#endregion
</script>

<style type="text/css" lang="scss">

    .list-item-no-child .mdi-chevron-down {
        display: none !important;
    }

    .menu-container, .menu-list {
        height: 100%;
    }

    .menu-list {
        display: flex;
        flex-direction: column;
        overflow-x: hidden !important;
    }

    .v-breadcrumbs-item .v-icon {
        margin-inline-start: 0 !important;
        -webkit-margin-start: 0 !important;
    }

    .v-breadcrumbs-item .v-list-item .v-icon {
        font-size: calc(var(--v-icon-size-multiplier) * 1.75em);
    }

    .v-breadcrumbs .v-breadcrumbs-item .breadcrumbs-item-node {
        padding-top: 7px;
    }

    .v-breadcrumbs .v-breadcrumbs-item .breadcrumbs-item-node.pointer::after {
        border-right: solid 8px transparent;
        border-left: solid 8px transparent;
        z-index: 99;
        content: '';
        height: 0;
        width: 0;
        margin: auto;
        border-bottom: solid 8px rgb(var(--v-theme-secondary));
        display: block;
        transform: translateY(-900px);
        transition-property: transform;
        transition-delay: 0.0s;
    }

    .v-breadcrumbs .v-breadcrumbs-item .breadcrumbs-item-node-active.pointer::after {
        transform: translateY(0%);
    }
</style>

<style lang="scss" scoped>
    .v-breadcrumbs-item .popup-menu {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .breadcrumbs-item-menu {
        position: absolute;
        border-radius: 3px;
        width: auto;
        top: calc(v-bind(popupMenuHeight) * 1px);
        align-self: center;
        display: flex;
        flex-direction: column;
    }

    .breadcrumbs-item-menu.align-left {
        align-self: flex-start;
    }

    .breadcrumbs-item-menu.align-right {
        align-self: flex-end;
    }

    .theme--dark.v-list {
        background-color: inherit;
    }

    a.menu-item {
        text-decoration: none;
        display: flex;
    }

    div.admin-menu .row {
        margin: 0;
    }

    .v-tooltip__content .v-list-item__icon {
        margin-right: 12px;
    }

    .v-tooltip__content {
        pointer-events: initial;
        opacity: 1 !important;
        background: var(--v-accent-base) !important;
    }

    .breadcrumb {
        width: 90%;
        margin: auto;
    }

    .v-breadcrumbs li, .v-breadcrumbs a {
        position: relative;
        margin: 10px 0px;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .menu-list .v-list-group__items .v-list-item.subItem {
        padding-left: 4px !important;
        padding-inline: 4px !important;
    }

    .v-breadcrumbs-item .v-icon.home-icon {
        font-size: 1.5em !important;
    }

    .breadcrumbs-placeholder {
        height: 48px;
        position: relative;

        .breadcrumbs-container {
            height: 48px;
            width: 100%;
            position: absolute;
            overflow-x: scroll;

            .items-breadcrumbs {
                position: absolute;
            }
        }

        .breadcrumbs-container-open {
            height: 600px;
        }
    }
</style>
