import { ref } from "vue";

export const useElementPartiallyVisibility = (target: any,
    windowOffset: { x: number, y: number } = { x: 0, y: 0 },
    visibilityRate: number = 100) =>
{

    const visibility = ref<{
        top: boolean,
        right: boolean,
        bottom: boolean,
        left: boolean,
        isVisible: boolean,
        hiddenTop: number,
        hiddenRight: number,
        hiddenBottom: number,
        hiddenLeft: number,
    }>({
        top: false,
        right: false,
        bottom: false,
        left: false,
        isVisible: false,
        hiddenTop: 0,
        hiddenRight: 0,
        hiddenBottom: 0,
        hiddenLeft: 0
    });

    const $el = (target.$el ?? target) as HTMLElement;
    if ($el) {
        const { top: elTop, left: elLeft, right: elRight, bottom: elBottom, width: elWidth, height: elHeigt } = $el.getBoundingClientRect();

        const rate = visibilityRate / 100;
        const windowWidth = window.innerWidth - windowOffset.x;
        const windowHeight = window.innerHeight - windowOffset.y;

        const hiddenTop = elTop < windowOffset.y ? Math.abs(elTop) : 0;
        const hiddenRight = elRight > windowWidth ? elRight - windowWidth : 0;
        const hiddenBottom = elBottom > windowHeight ? elBottom - windowHeight : 0;
        const hiddenLeft = elLeft < windowOffset.x ? Math.abs(elLeft) : 0;

        const top = elTop >= windowOffset.y || hiddenTop / elHeigt > rate;
        const right = elRight <= windowWidth || (elWidth - hiddenRight) / elWidth > rate;
        const bottom = elBottom <= windowHeight || (elHeigt - hiddenBottom) / elHeigt > rate;
        const left = elLeft >= windowOffset.x || hiddenLeft / elWidth > rate;
        const isVisible = top && right && bottom && left;

        visibility.value = { top, right, bottom, left, isVisible, hiddenTop, hiddenRight, hiddenBottom, hiddenLeft };
    }

    return visibility;
}